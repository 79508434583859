import { defineNuxtRouteMiddleware, navigateTo } from "#imports";
import { useRestaurantDetailStore } from "~/stores/restaurantDetail";
export default defineNuxtRouteMiddleware(() => {
  const restaurantDetailStore = useRestaurantDetailStore();

  // check if restaurant detail store has value or not by check name state
  // return to restaurant page if not
  if (!restaurantDetailStore.name.length) {
    return navigateTo({
      name: "lang-restaurants-slug",
    });
  }
});
